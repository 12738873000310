import { CircularProgress } from '@material-ui/core';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grid from '@material-ui/core/Grid';
import Grow from '@material-ui/core/Grow';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import Button, { ButtonProps } from 'ra-ui-materialui/esm/button/Button';
import React from 'react';
import { NotificationType, Record } from 'react-admin';
import { smplColors } from '../layout/themes';
import { CustomDeleteWithConfirmButton, CustomDeleteWithConfirmButtonProps } from '../utils/CustomDeleteWithConfirmButton';

type SplitButtonAndOptionFnProps = {
  data: Record
  resource: string
  confirmClasses?: string
  refresh: (hard?: boolean) => void
  notify: (message: string, type?: NotificationType, messageArgs?: any, undoable?: boolean, autoHideDuration?: number) => void
}

type OptionFnReturnType = (ButtonProps | ({
  actionType: 'withConfirmButton',
} & CustomDeleteWithConfirmButtonProps))

export type SplitButtonProps = {
  defaultSelectedIndex?: number
  optionFn: (props: SplitButtonAndOptionFnProps) => OptionFnReturnType[]
} & SplitButtonAndOptionFnProps

type ButtonCompProps = {
  isLoading: boolean
} & OptionFnReturnType

const DefaultButton = (props: ButtonCompProps) => <Button
  {...props}
  startIcon={props.isLoading ? <CircularProgress size="1rem" /> : props.icon}
/>

const ButtonComp = (props: ButtonCompProps) => {
  if (!('actionType' in props)) {
    return <DefaultButton {...props} />
  }

  switch (props.actionType) {
    case 'withConfirmButton': {
      return <CustomDeleteWithConfirmButton
        {...props}
        icon={props.isLoading ? <CircularProgress size="1rem" /> : props.icon}
        style={{
          ...props.style,
          borderTopRightRadius: 0,
          borderBottomRightRadius: 0,
          borderRight: 0
        }}
      />
    }
  }

}

export default function SplitActionButton(props: SplitButtonProps) {
  const { optionFn, defaultSelectedIndex } = props
  const [open, setOpen] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false)
  const anchorRef = React.useRef<HTMLDivElement>(null);
  const [selectedIndex, setSelectedIndex] = React.useState(defaultSelectedIndex || 0);
  const options = optionFn(props)
  const currentOption = options[selectedIndex]

  const resolveOnClick = async (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    if (typeof currentOption.onClick !== 'function') {
      return
    }

    setIsLoading(true)

    try {
      await currentOption.onClick(event)
    } catch {}

    setIsLoading(false)
  }

  const handleMenuItemClick = (
    _event: React.MouseEvent<HTMLLIElement, MouseEvent>,
    index: number,
  ) => {
    setSelectedIndex(index);
    setOpen(false);
  };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event: React.MouseEvent<Document, MouseEvent>) => {
    if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
      return;
    }

    setOpen(false);
  };

  return (
    <div
      style={{
        padding: 0
      }}
    >
      <Grid container xs={12} direction="column" alignItems="center">
        <Grid item xs={12}>
          <ButtonGroup
            variant="outlined"
            color="default"
            ref={anchorRef}
          >
            {<ButtonComp
              {...currentOption}
              isLoading={isLoading}
              onClick={resolveOnClick}
              color='default'
              style={{
                color: smplColors.primary.main
              }}
              disabled={isLoading}
            />}
            <Button
              variant="outlined"
              onClick={handleToggle}
              color='default'
              style={{
                color: smplColors.primary.main
              }}
              disabled={isLoading}
            >
                <ArrowDropDownIcon />
            </Button>
          </ButtonGroup>
          <Popper open={open} anchorEl={anchorRef.current} role={undefined} transition disablePortal>
            {({ TransitionProps, placement }) => (
              <Grow
                {...TransitionProps}
                style={{
                  transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
                }}
              >
                <Paper>
                  <ClickAwayListener onClickAway={handleClose}>
                    <MenuList>
                      {options.map((option, index) => (
                        <MenuItem
                          key={option.label}
                          selected={index === selectedIndex}
                          onClick={(event) => handleMenuItemClick(event, index)}
                        >
                          {option.label}
                        </MenuItem>
                      ))}
                    </MenuList>
                  </ClickAwayListener>
                </Paper>
              </Grow>
            )}
          </Popper>
        </Grid>
      </Grid>
    </div>
  );
}
