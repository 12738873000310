export const monitoringTags = {
  depositsOver500k: 'Deposit >= 500k CHF',
  freqDepositsAndPayouts: 'Frequent deposits & payouts',
  depostisOutScale5xSalary: '5x salary deposit',
  highRiskIndustry: 'High risk industry',
  pep: 'PEP',
  noAmlProof: 'No AML proof submitted',
  freqAddressChange: 'Frequent address change',
  highRiskOther: 'High risk (other)',
  buyAndSellWithin3Days: 'Buy & Sell <= 3 days',
  moreThan10Vaults: '> 10 vaults',
}

export const headersForApi = {
  'x-app-dev': '-',
  'x-app-version': '-',
  'x-app-test-env': '-',
  'x-app-build': '-',
  'x-platform': 'flexgold-admin-dashboard',
}
