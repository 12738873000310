import {
  Button,
  Container,
  Paper,
  TextField,
  makeStyles,
} from '@material-ui/core'
import { useState } from 'react'
import { Record, useNotify } from 'react-admin'
import { headersForApi } from '../../commons/flexgold/constants'
import { SMPL_FLEXGOLD_API_BASEURL } from '../../lib/config'
import { createSimpleAuthenticatedClient } from '../node-actions/user/lib/simpleClient'

const useStyles = makeStyles(() => ({
  formContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: '1rem',
    padding: '1rem',
  },
  buttonGroup: {
    display: 'flex',
    justifyContent: 'center',
    gap: '1rem',
    marginTop: '1rem',
  },
}))

export type CreatePowerOfAttorneyFormProps = {
  data: Record
}

const CreatePowerOfAttorneyForm = (props: CreatePowerOfAttorneyFormProps) => {
  const { data } = props
  const classes = useStyles()
  const notify = useNotify()
  const [userId, setUserId] = useState<string>('')
  const [loading, setLoading] = useState<boolean>(false)

  const handleCreatePowerOfAttorney = async (vaultId: string) => {
    setLoading(true)
    if (!userId || !vaultId) {
      notify('Please fill in both User ID and Vault ID')
      setLoading(false)
      return
    }

    try {
      const authClient = createSimpleAuthenticatedClient(
        SMPL_FLEXGOLD_API_BASEURL,
        { headers: headersForApi }
      )
      const response = await authClient(
        'POST',
        '/admin/vault-role/power-of-attorney/grant',
        {
          userId,
          vaultId: vaultId,
        }
      )

      if (response.statusCode === 201 && !response.error) {
        notify(response.data.message)
        setUserId('')
      } else {
        throw new Error('Failed to create Power of Attorney')
      }
    } catch (error) {
      notify(error.message || 'An error occurred')
    } finally {
      setLoading(false)
    }
  }

  const handleRevokePowerOfAttorney = async (vaultId: string) => {
    setLoading(true)
    if (!userId || !vaultId) {
      notify('Please fill in both User ID and Vault ID')
      setLoading(false)
      return
    }
    try {
      const authClient = createSimpleAuthenticatedClient(
        SMPL_FLEXGOLD_API_BASEURL,
        { headers: headersForApi }
      )
      const response = await authClient(
        'POST',
        `/admin/vault-role/power-of-attorney/revoke`,
        {
          userId,
          vaultId: vaultId,
        }
      )

      if (response.statusCode === 201 && !response.error) {
        notify(response.data.message)
        setUserId('')
      } else {
        throw new Error('Failed to remove Power of Attorney')
      }
    } catch (error) {
      notify(error.message || 'An error occurred')
    } finally {
      setLoading(false)
    }
  }

  return (
    <Container>
      <Paper>
        <Container className={classes.formContainer}>
          <TextField
            fullWidth
            variant="outlined"
            label="User ID"
            value={userId}
            onChange={(e) => setUserId(e.target.value)}
          />

          <div className={classes.buttonGroup}>
            <Button
              color="primary"
              variant="contained"
              onClick={() => handleCreatePowerOfAttorney(data.id as any)}
            >
              {loading ? 'Processing...' : 'Grant'}
            </Button>

            <Button
              color="secondary"
              variant="contained"
              onClick={() => handleRevokePowerOfAttorney(data.id as any)}
            >
              {loading ? 'Processing...' : 'Revoke'}
            </Button>
          </div>
        </Container>
      </Paper>
    </Container>
  )
}

export default CreatePowerOfAttorneyForm
