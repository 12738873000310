// Select options:
// EU members countries + US and their country code
// first is the default
export const countryOptions = [
  { label: 'Germany (DE)', value: 'DE' },
  { label: 'Austria (AT)', value: 'AT' },
  { label: 'Switzerland (CH)', value: 'CH' },
  { label: 'Global (ALL)', value: 'ALL' },
  { label: 'Belgium (BE)', value: 'BE' },
  { label: 'Alto Aldige (AA)', value: 'AA' },
  { label: 'Bulgaria (BG)', value: 'BG' },
  { label: 'Croatia (HR)', value: 'HR' },
  { label: 'Cyprus (CY)', value: 'CY' },
  { label: 'Czech Republic (CZ)', value: 'CZ' },
  { label: 'Denmark (DK)', value: 'DK' },
  { label: 'Estonia (EE)', value: 'EE' },
  { label: 'Finland (FI)', value: 'FI' },
  { label: 'France (FR)', value: 'FR' },
  { label: 'Greece (GR)', value: 'GR' },
  { label: 'Hungary (HU)', value: 'HU' },
  { label: 'Ireland (IE)', value: 'IE' },
  { label: 'Italy (IT)', value: 'IT' },
  { label: 'Latvia (LV)', value: 'LV' },
  { label: 'Lithuania (LT)', value: 'LT' },
  { label: 'Luxembourg (LU)', value: 'LU' },
  { label: 'Malta (MT)', value: 'MT' },
  { label: 'Netherlands (NL)', value: 'NL' },
  { label: 'Poland (PO)', value: 'PO' },
  { label: 'Portugal (PT)', value: 'PT' },
  { label: 'Romania (RO)', value: 'RO' },
  { label: 'Slovakia (SK)', value: 'SK' },
  { label: 'Slovenia (SI)', value: 'SI' },
  { label: 'Spain (ES)', value: 'ES' },
  { label: 'Sweden (SE)', value: 'SE' },
  { label: 'United States (US)', value: 'US' },
  { value: 'AF', label: 'Afghanistan (AF)' },
  { value: 'AX', label: 'Aland Islands (AX)' },
  { value: 'AL', label: 'Albania (AL)' },
  { value: 'DZ', label: 'Algeria (DZ)' },
  { value: 'AS', label: 'American Samoa (AS)' },
  { value: 'AD', label: 'Andorra (AD)' },
  { value: 'AO', label: 'Angola (AO)' },
  { value: 'AI', label: 'Anguilla (AI)' },
  { value: 'AQ', label: 'Antarctica (AQ)' },
  { value: 'AG', label: 'Antigua And Barbuda (AG)' },
  { value: 'AR', label: 'Argentina (AR)' },
  { value: 'AM', label: 'Armenia (AM)' },
  { value: 'AW', label: 'Aruba (AW)' },
  { value: 'AU', label: 'Australia (AU)' },
  { value: 'AZ', label: 'Azerbaijan (AZ)' },
  { value: 'BS', label: 'Bahamas (BS)' },
  { value: 'BH', label: 'Bahrain (BH)' },
  { value: 'BD', label: 'Bangladesh (BD)' },
  { value: 'BB', label: 'Barbados (BB)' },
  { value: 'BY', label: 'Belarus (BY)' },
  { value: 'BZ', label: 'Belize (BZ)' },
  { value: 'BJ', label: 'Benin (BJ)' },
  { value: 'BM', label: 'Bermuda (BM)' },
  { value: 'BT', label: 'Bhutan (BT)' },
  { value: 'BO', label: 'Bolivia (BO)' },
  { value: 'BA', label: 'Bosnia And Herzegovina (BA)' },
  { value: 'BW', label: 'Botswana (BW)' },
  { value: 'BV', label: 'Bouvet Island (BV)' },
  { value: 'BR', label: 'Brazil (BR)' },
  { value: 'IO', label: 'British Indian Ocean Territory (IO)' },
  { value: 'BN', label: 'Brunei Darussalam (BN)' },
  { value: 'BF', label: 'Burkina Faso (BF)' },
  { value: 'BI', label: 'Burundi (BI)' },
  { value: 'KH', label: 'Cambodia (KH)' },
  { value: 'CM', label: 'Cameroon (CM)' },
  { value: 'CA', label: 'Canada (CA)' },
  { value: 'CV', label: 'Cape Verde (CV)' },
  { value: 'KY', label: 'Cayman Islands (KY)' },
  { value: 'CF', label: 'Central African Republic (CF)' },
  { value: 'TD', label: 'Chad (TD)' },
  { value: 'CL', label: 'Chile (CL)' },
  { value: 'CN', label: 'China (CN)' },
  { value: 'CX', label: 'Christmas Island (CX)' },
  { value: 'CC', label: 'Cocos (Keeling) Islands (CC)' },
  { value: 'CO', label: 'Colombia (CO)' },
  { value: 'KM', label: 'Comoros (KM)' },
  { value: 'CG', label: 'Congo (CG)' },
  { value: 'CD', label: 'Congo, Democratic Republic (CD)' },
  { value: 'CK', label: 'Cook Islands (CK)' },
  { value: 'CR', label: 'Costa Rica (CR)' },
  { value: 'CI', label: "Cote D'Ivoire (CI)" },
  { value: 'CU', label: 'Cuba (CU)' },
  { value: 'DJ', label: 'Djibouti (DJ)' },
  { value: 'DM', label: 'Dominica (DM)' },
  { value: 'DO', label: 'Dominican Republic (DO)' },
  { value: 'EC', label: 'Ecuador (EC)' },
  { value: 'EG', label: 'Egypt (EG)' },
  { value: 'SV', label: 'El Salvador (SV)' },
  { value: 'GQ', label: 'Equatorial Guinea (GQ)' },
  { value: 'ER', label: 'Eritrea (ER)' },
  { value: 'ET', label: 'Ethiopia (ET)' },
  { value: 'FK', label: 'Falkland Islands (Malvinas) (FK)' },
  { value: 'FO', label: 'Faroe Islands (FO)' },
  { value: 'FJ', label: 'Fiji (FJ)' },
  { value: 'GF', label: 'French Guiana (GF)' },
  { value: 'PF', label: 'French Polynesia (PF)' },
  { value: 'TF', label: 'French Southern Territories (TF)' },
  { value: 'GA', label: 'Gabon (GA)' },
  { value: 'GM', label: 'Gambia (GM)' },
  { value: 'GE', label: 'Georgia (GE)' },
  { value: 'GH', label: 'Ghana (GH)' },
  { value: 'GI', label: 'Gibraltar (GI)' },
  { value: 'GL', label: 'Greenland (GL)' },
  { value: 'GD', label: 'Grenada (GD)' },
  { value: 'GP', label: 'Guadeloupe (GP)' },
  { value: 'GU', label: 'Guam (GU)' },
  { value: 'GT', label: 'Guatemala (GT)' },
  { value: 'GG', label: 'Guernsey (GG)' },
  { value: 'GN', label: 'Guinea (GN)' },
  { value: 'GW', label: 'Guinea-Bissau (GW)' },
  { value: 'GY', label: 'Guyana (GY)' },
  { value: 'HT', label: 'Haiti (HT)' },
  { value: 'HM', label: 'Heard Island & Mcdonald Islands (HM)' },
  { value: 'VA', label: 'Holy See (Vatican City State) (VA)' },
  { value: 'HN', label: 'Honduras (HN)' },
  { value: 'HK', label: 'Hong Kong (HK)' },
  { value: 'IS', label: 'Iceland (IS)' },
  { value: 'IN', label: 'India (IN)' },
  { value: 'ID', label: 'Indonesia (ID)' },
  { value: 'IR', label: 'Iran, Islamic Republic Of (IR)' },
  { value: 'IQ', label: 'Iraq (IQ)' },
  { value: 'IM', label: 'Isle Of Man (IM)' },
  { value: 'IL', label: 'Israel (IL)' },
  { value: 'JM', label: 'Jamaica (JM)' },
  { value: 'JP', label: 'Japan (JP)' },
  { value: 'JE', label: 'Jersey (JE)' },
  { value: 'JO', label: 'Jordan (JO)' },
  { value: 'KZ', label: 'Kazakhstan (KZ)' },
  { value: 'KE', label: 'Kenya (KE)' },
  { value: 'KI', label: 'Kiribati (KI)' },
  { value: 'KR', label: 'Korea (KR)' },
  { value: 'KW', label: 'Kuwait (KW)' },
  { value: 'KG', label: 'Kyrgyzstan (KG)' },
  { value: 'LA', label: "Lao People's Democratic Republic (LA)" },
  { value: 'LB', label: 'Lebanon (LB)' },
  { value: 'LS', label: 'Lesotho (LS)' },
  { value: 'LR', label: 'Liberia (LR)' },
  { value: 'LY', label: 'Libyan Arab Jamahiriya (LY)' },
  { value: 'LI', label: 'Liechtenstein (LI)' },
  { value: 'MO', label: 'Macao (MO)' },
  { value: 'MK', label: 'Macedonia (MK)' },
  { value: 'MG', label: 'Madagascar (MG)' },
  { value: 'MW', label: 'Malawi (MW)' },
  { value: 'MY', label: 'Malaysia (MY)' },
  { value: 'MV', label: 'Maldives (MV)' },
  { value: 'ML', label: 'Mali (ML)' },
  { value: 'MH', label: 'Marshall Islands (MH)' },
  { value: 'MQ', label: 'Martinique (MQ)' },
  { value: 'MR', label: 'Mauritania (MR)' },
  { value: 'MU', label: 'Mauritius (MU)' },
  { value: 'YT', label: 'Mayotte (YT)' },
  { value: 'MX', label: 'Mexico (MX)' },
  { value: 'FM', label: 'Micronesia, Federated States Of (FM)' },
  { value: 'MD', label: 'Moldova (MD)' },
  { value: 'MC', label: 'Monaco (MC)' },
  { value: 'MN', label: 'Mongolia (MN)' },
  { value: 'ME', label: 'Montenegro (ME)' },
  { value: 'MS', label: 'Montserrat (MS)' },
  { value: 'MA', label: 'Morocco (MA)' },
  { value: 'MZ', label: 'Mozambique (MZ)' },
  { value: 'MM', label: 'Myanmar (MM)' },
  { value: 'NA', label: 'Namibia (NA)' },
  { value: 'NR', label: 'Nauru (NR)' },
  { value: 'NP', label: 'Nepal (NP)' },
  { value: 'AN', label: 'Netherlands Antilles (AN)' },
  { value: 'NC', label: 'New Caledonia (NC)' },
  { value: 'NZ', label: 'New Zealand (NZ)' },
  { value: 'NI', label: 'Nicaragua (NI)' },
  { value: 'NE', label: 'Niger (NE)' },
  { value: 'NG', label: 'Nigeria (NG)' },
  { value: 'NU', label: 'Niue (NU)' },
  { value: 'NF', label: 'Norfolk Island (NF)' },
  { value: 'MP', label: 'Northern Mariana Islands (MP)' },
  { value: 'NO', label: 'Norway (NO)' },
  { value: 'OM', label: 'Oman (OM)' },
  { value: 'PK', label: 'Pakistan (PK)' },
  { value: 'PW', label: 'Palau (PW)' },
  { value: 'PS', label: 'Palestinian Territory, Occupied (PS)' },
  { value: 'PA', label: 'Panama (PA)' },
  { value: 'PG', label: 'Papua New Guinea (PG)' },
  { value: 'PY', label: 'Paraguay (PY)' },
  { value: 'PE', label: 'Peru (PE)' },
  { value: 'PH', label: 'Philippines (PH)' },
  { value: 'PN', label: 'Pitcairn (PN)' },
  { value: 'PL', label: 'Poland (PL)' },
  { value: 'PR', label: 'Puerto Rico (PR)' },
  { value: 'QA', label: 'Qatar (QA)' },
  { value: 'RE', label: 'Reunion (RE)' },
  { value: 'RU', label: 'Russian Federation (RU)' },
  { value: 'RW', label: 'Rwanda (RW)' },
  { value: 'BL', label: 'Saint Barthelemy (BL)' },
  { value: 'SH', label: 'Saint Helena (SH)' },
  { value: 'KN', label: 'Saint Kitts And Nevis (KN)' },
  { value: 'LC', label: 'Saint Lucia (LC)' },
  { value: 'MF', label: 'Saint Martin (MF)' },
  { value: 'PM', label: 'Saint Pierre And Miquelon (PM)' },
  { value: 'VC', label: 'Saint Vincent And Grenadines (VC)' },
  { value: 'WS', label: 'Samoa (WS)' },
  { value: 'SM', label: 'San Marino (SM)' },
  { value: 'ST', label: 'Sao Tome And Principe (ST)' },
  { value: 'SA', label: 'Saudi Arabia (SA)' },
  { value: 'SN', label: 'Senegal (SN)' },
  { value: 'RS', label: 'Serbia (RS)' },
  { value: 'SC', label: 'Seychelles (SC)' },
  { value: 'SL', label: 'Sierra Leone (SL)' },
  { value: 'SG', label: 'Singapore (SG)' },
  { value: 'SB', label: 'Solomon Islands (SB)' },
  { value: 'SO', label: 'Somalia (SO)' },
  { value: 'ZA', label: 'South Africa (ZA)' },
  { value: 'GS', label: 'South Georgia And Sandwich Isl. (GS)' },
  { value: 'LK', label: 'Sri Lanka (LK)' },
  { value: 'SD', label: 'Sudan (SD)' },
  { value: 'SR', label: 'Suriname (SR)' },
  { value: 'SJ', label: 'Svalbard And Jan Mayen (SJ)' },
  { value: 'SZ', label: 'Swaziland (SZ)' },
  { value: 'SY', label: 'Syrian Arab Republic (SY)' },
  { value: 'TW', label: 'Taiwan (TW)' },
  { value: 'TJ', label: 'Tajikistan (TJ)' },
  { value: 'TZ', label: 'Tanzania (TZ)' },
  { value: 'TH', label: 'Thailand (TH)' },
  { value: 'TL', label: 'Timor-Leste (TL)' },
  { value: 'TG', label: 'Togo (TG)' },
  { value: 'TK', label: 'Tokelau (TK)' },
  { value: 'TO', label: 'Tonga (TO)' },
  { value: 'TT', label: 'Trinidad And Tobago (TT)' },
  { value: 'TN', label: 'Tunisia (TN)' },
  { value: 'TR', label: 'Turkey (TR)' },
  { value: 'TM', label: 'Turkmenistan (TM)' },
  { value: 'TC', label: 'Turks And Caicos Islands (TC)' },
  { value: 'TV', label: 'Tuvalu (TV)' },
  { value: 'UG', label: 'Uganda (UG)' },
  { value: 'UA', label: 'Ukraine (UA)' },
  { value: 'AE', label: 'United Arab Emirates (AE)' },
  { value: 'GB', label: 'United Kingdom (GB)' },
  { value: 'UM', label: 'United States Outlying Islands (UM)' },
  { value: 'UY', label: 'Uruguay (UY)' },
  { value: 'UZ', label: 'Uzbekistan (UZ)' },
  { value: 'VU', label: 'Vanuatu (VU)' },
  { value: 'VE', label: 'Venezuela (VE)' },
  { value: 'VN', label: 'Vietnam (VN)' },
  { value: 'VG', label: 'Virgin Islands, British (VG)' },
  { value: 'VI', label: 'Virgin Islands, U.S. (VI)' },
  { value: 'WF', label: 'Wallis And Futuna (WF)' },
  { value: 'EH', label: 'Western Sahara (EH)' },
  { value: 'YE', label: 'Yemen (YE)' },
  { value: 'ZM', label: 'Zambia (ZM)' },
  { value: 'ZW', label: 'Zimbabwe (ZW)' },
]

export const propertiesOptions = [
  { label: 'AVOD', value: 'AVOD' },
  { label: 'SVOD', value: 'SVOD' },
  { label: 'SVOD_Amazon', value: 'SVOD_Amazon' },
  { label: 'TVOD', value: 'TVOD' },
  { label: 'FVOD', value: 'FVOD' },
  { label: 'FAST', value: 'FAST' },
  { label: 'DRM', value: 'DRM' },
  { label: 'EST', value: 'EST' },
]

// ARD
export const channelsOption_ARD = [
  { label: 'Ard', value: 'ard' },
  { label: 'Imagion', value: 'imagion' },
  { label: 'YouTube', value: 'youtube' },
  { label: 'FAST-Krimi', value: 'FAST-Krimi' },
  { label: 'FAST-Lindi', value: 'FAST-Lindi' },
  { label: 'JOYN-Krimi', value: 'JOYN-Krimi' },
  { label: 'JOYN-Lindenstraße', value: 'JOYN-Lindenstraße' },
  { label: 'JOYN-Elisa', value: 'JOYN-Elisa' },
]

// NK
export const channelsOption_NK = [
  { label: 'Netzkino', value: 'netzkino' },
  { label: 'CAPI', value: 'capi' },
  { label: 'Netzkino Plus', value: 'Netzkino Plus' },
  { label: 'Netzkino Select', value: 'Netzkino Select' },
  { label: 'Fantasja', value: 'Fantasja' },
  { label: 'Fabella', value: 'Fabella' },
  { label: 'Nanoki', value: 'Nanoki' },
  { label: 'TemporaTV', value: 'TemporaTV' },
  { label: 'Heimatkino.TV', value: 'Heimatkino.TV' },
  { label: 'Screamtime', value: 'Screamtime' },
  { label: 'Rashland', value: 'Rashland' },
  { label: 'Dzango', value: 'Dzango' },
  { label: 'Movies Select', value: 'Movies Select' },
  { label: 'Toonzoom', value: 'Toonzoom' },
  { label: 'Bulldox', value: 'Bulldox' },
  { label: 'Draco', value: 'Draco' },
  { label: 'Origjnals', value: 'Origjnals' },
  { label: 'Wolkesieben', value: 'Wolkesieben' },
  { label: 'Highresworld', value: 'Highresworld' },
  { label: 'TitanumX', value: 'TitanumX' },
  { label: 'UTOPJA', value: 'UTOPJA' },
  { label: 'RETROFLIX', value: 'RETROFLIX' },
  { label: 'terragonia', value: 'terragonia' },
  { label: 'Globe9', value: 'Globe9' },
  { label: 'Love Is a Wonder', value: 'Love Is a Wonder' },
]

export const portalsOption_NK = [
  { label: 'Web', value: 'Web' },
  { label: 'Android App', value: 'Android App' },
  { label: 'Amazon Fire TV', value: 'Amazon Fire TV' },
  { label: 'Android TV', value: 'Android TV' },
  { label: 'Apple TV', value: 'Apple TV' },
  { label: 'Magenta TV', value: 'Magenta TV' },
  { label: 'Huawei App', value: 'Huawei App' },
  { label: 'iOS App', value: 'iOS App' },
  { label: 'Panasonic', value: 'Panasonic' },
  { label: 'Samsung', value: 'Samsung' },
  { label: 'Sony', value: 'Sony' },
  { label: 'Technisat', value: 'Technisat' },
  { label: 'Toshiba', value: 'Toshiba' },
  { label: 'Windows', value: 'Windows' },
  { label: 'Hisense 2015-2018 (Foxxum)', value: 'Hisense 2015-2018 (Foxxum)' },
  {
    label: 'Vestel 2018 – 2020 (Foxxum)',
    value: 'Vestel 2018 – 2020 (Foxxum)',
  },
  { label: 'TCL 2016 -2018 (Foxxum)', value: 'TCL 2016 -2018 (Foxxum)' },
  { label: 'Metz 2016-2018 (Foxxum)', value: 'Metz 2016-2018 (Foxxum)' },
  {
    label: 'Blaupunkt 2016-2018 (Foxxum)',
    value: 'Blaupunkt 2016-2018 (Foxxum)',
  },
  {
    label: 'Eternity 2016 -2017 (Foxxum)',
    value: 'Eternity 2016 -2017 (Foxxum)',
  },
  { label: 'Haier 2016-2018 (Foxxum)', value: 'Haier 2016-2018 (Foxxum)' },
  { label: 'Sharp 2016 -2018 (Foxxum)', value: 'Sharp 2016 -2018 (Foxxum)' },
  { label: 'Orion 2016-2017 (Foxxum)', value: 'Orion 2016-2017 (Foxxum)' },
  { label: 'JTC 2016-2018 (Foxxum)', value: 'JTC 2016-2018 (Foxxum)' },
  { label: 'LG', value: 'LG' },
]

export const foreignPortalsOption_NK = [
  { label: 'YouTube (AVOD)', value: 'YouTube' },
  { label: 'YouTube Premium (SVOD)', value: 'YouTube Premium' },
  { label: '1&1', value: '1&1' },
  { label: 'blue TV Swisscom ', value: 'blue TV Swisscom ' },
  { label: 'Dailyme TV', value: 'Dailyme TV' },
  { label: 'Huawei Video ', value: 'Huawei Video ' },
  { label: 'Joyn', value: 'Joyn' },
  { label: 'LG - Channels', value: 'LG - Channels' },
  { label: 'Philips TV', value: 'Philips TV' },
  { label: 'Samsung TV Plus', value: 'Samsung TV Plus' },
  { label: 'Rakuten TV', value: 'Rakuten TV' },
  { label: 'Rlaxx.tv', value: 'Rlaxx.tv' },
  { label: 'Roku', value: 'Roku' },
  { label: 'Vodafone GigaTV', value: 'Vodafone GigaTV' },
  { label: 'Vodafone Entertainment', value: 'Vodafone Entertainment' },
  { label: 'waipu.tv', value: 'waipu.tv' },
  { label: 'Zattoo', value: 'Zattoo' },
  { label: 'Facebook Watch', value: 'Facebook Watch' },
]

export const currencyCodes = [
  { label: 'none (empty value)', value: undefined, symbol: undefined },
  { label: 'EUR (€)', value: 'EUR', symbol: '€' },
  { label: 'HUF (Ft)', value: 'HUF', symbol: 'Ft' },
  { label: 'GBP (£)', value: 'GBP', symbol: '£' },
  { label: 'CHF', value: 'CHF', symbol: 'CHF' },
  { label: 'PLN (zł)', value: 'PLN', symbol: 'zł' },
  { label: 'USD ($}', value: 'USD', symbol: '$' },
]
