import { makeStyles } from '@material-ui/core/styles'
import { fade } from '@material-ui/core/styles/colorManipulator'
import ActionDelete from '@material-ui/icons/Delete'
import {
  Record,
  RedirectionSideEffect,
  useDeleteWithConfirmController,
} from 'ra-core'
import Button, { ButtonProps } from 'ra-ui-materialui/esm/button/Button'
import {
  FC,
  Fragment,
  ReactElement,
  ReactEventHandler,
  SyntheticEvent,
  useCallback,
} from 'react'
import { CustomDeleteConfirm } from './CustomDeleteConfirm'

export const CustomDeleteWithConfirmButton: FC<CustomDeleteWithConfirmButtonProps> = (
  props
) => {
  const {
    basePath,
    classes: classesOverride,
    className,
    btnClassName,
    confirmClasses,
    confirm,
    onConfirm,
    confirmTitle,
    confirmContent,
    icon = defaultIcon,
    label = 'ra.action.delete',
    onClick,
    record,
    resource = '',
    redirect = 'list',
    disableCancelBtn,
    forceOpen,
    ...rest
  } = props
  const classes = useStyles(props)
  const {
    open,
    loading,
    handleDialogOpen,
    handleDialogClose,
    handleDelete,
  } = useDeleteWithConfirmController({
    resource,
    record,
    redirect,
    basePath,
    onClick,
  })

  const onConfirmCb = useCallback(
    (e: SyntheticEvent<Element, Event>) => {
      if (typeof onConfirm === 'function') {
        onConfirm(e)
        handleDialogClose(e)
      } else {
        handleDelete(e)
      }
    },
    [onConfirm, handleDialogClose]
  )

  return (
    <Fragment>
      <Button
        onClick={handleDialogOpen}
        label={label}
        className={btnClassName ?? classes.deleteButton}
        key="button"
        {...rest}
      >
        {icon}
      </Button>
      <CustomDeleteConfirm
        disableCancelBtn={disableCancelBtn}
        isOpen={forceOpen ?? open}
        loading={loading}
        title={
          confirmTitle ||
          `Are you sure to delete this ${resource} ${
            record.title ? record.title : 'object'
          }?`
        }
        content={confirmContent}
        // translateOptions={{
        //   name: translate(`resources.${resource}.forcedCaseName`, {
        //     smart_count: 1,
        //     _: inflection.humanize(
        //       translate(`resources.${resource}.name`, {
        //         smart_count: 1,
        //         _: inflection.singularize(resource),
        //       }),
        //       true
        //     ),
        //   }),
        //   id: record.id,
        // }}
        onConfirm={onConfirmCb}
        onClose={handleDialogClose}
        confirmClasses={confirmClasses}
        confirm={confirm}
      />
    </Fragment>
  )
}

const defaultIcon = <ActionDelete />

const useStyles = makeStyles(
  (theme) => ({
    deleteButton: {
      color: theme.palette.error.main,
      '&:hover': {
        backgroundColor: fade(theme.palette.error.main, 0.12),
        // Reset on mouse devices
        '@media (hover: none)': {
          backgroundColor: 'transparent',
        },
      },
    },
  }),
  { name: 'RaDeleteWithConfirmButton' }
)

interface Props {
  basePath?: string
  classes?: object
  className?: string
  confirmTitle?: string
  confirmContent: string | React.ReactNode
  confirmClasses?: string
  icon?: ReactElement
  label?: string
  onClick?: ReactEventHandler<any>
  record: Record
  redirect?: RedirectionSideEffect
  resource?: string
  // May be injected by Toolbar - sanitized in Button
  handleSubmit?: (event?: SyntheticEvent<HTMLFormElement>) => Promise<Object>
  handleSubmitWithRedirect?: (redirect?: RedirectionSideEffect) => void
  invalid?: boolean
  pristine?: boolean
  saving?: boolean
  submitOnEnter?: boolean
  undoable?: boolean
  btnClassName?: string
  confirm?: string
  disableCancelBtn?: boolean
  onConfirm?: (e: SyntheticEvent<Element, Event>) => void
  /** Overrides modal open state.
   * - If **true** the modal is shown regardless of internal state
   * - If **false** the modal is hidden regardless of internal state
   * - If **undefined** the modal's shown/hidden state is handled internally
   */
  forceOpen?: boolean
}

export type CustomDeleteWithConfirmButtonProps = Props & ButtonProps
