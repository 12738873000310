import {
  Button,
  Card,
  CircularProgress,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from '@material-ui/core'
import { Parser } from 'json2csv'
import { upperFirst } from 'lodash'
import { useState } from 'react'
import { useNotify } from 'react-admin'
import { useQuery } from 'react-apollo-hooks'
import {
  GetCampaignStats,
  GetCampaignStatsVariables,
} from '../../__generated__/flexgold/GetCampaignStats'
import { getCampaignStats } from '../../graphQL/flexgold/campaign'
import { DialogSelect } from './dialogSelect'

const useStyles = makeStyles({
  root: {
    padding: '2rem 2rem 2rem 2rem',
    borderRadius: 25,
  },
  tableContainer: { maxHeight: '65vh' },
})

const columnLabels = {
  register: 'Register',
  login: 'Login',
  masterDataStarted: 'Master Data Started',
  masterDataPersonalDetailsAd: 'Master Data Personal Details Ad',
  addedMasterData: 'Added Master Data',
  createdBankAccount: 'Created Bank Account',
  kycRedirectedToIdNow: 'KYC Redirected To Id Now',
  kycPart1Finished: 'KYC Part1 Finished',
  kycRedirectedToChat: 'KYC Redirected To Chat',
  kycFinished: 'KYC Finished',
  beginVaultCreation: 'Begin Vault Creation',
  vaultTypeSelected: 'Vault Type Selected',
  vaultCreated: 'Vault Created',
  prepaidProcessStarted: 'Prepaid Process Started',
  vaultDetailsCopied: 'Vault Details Copied',
  transferIbanCopied: 'Transfer IBAN Copied',
  instantOrderStarted: 'Instant Order Started',
  instantOrderChoseCurrency: 'Instant Order Chose Currency',
  instantOrderChoseValue: 'Instant Order Chose Value',
  instantOrderSent: 'Instant Order Sent',
  instantOrderExecuted: 'Instant Order Executed',
  futureOrderStarted: 'Future Order Started',
  futureOrderChoseCurrency: 'Future Order Chose Currency',
  futureOrderChoseDate: 'Future Order Chose Date',
  futureOrderChoseValue: 'Future Order Chose Value',
  futureOrderChoseLimit: 'Future Order Chose Limit',
  futureOrderSent: 'Future Order Sent',
  futureOrderExecuted: 'Future Order Executed',
  repeatOrderStarted: 'Repeat Order Started',
  repeatOrderChoseCurrency: 'Repeat Order Chose Currency',
  repeatOrderChoseDate: 'Repeat Order Chose Date',
  repeatOrderPlanChoseValue: 'Repeat Order Plan Chose Value',
  repeatOrderPlanChoseLimit: 'Repeat Order Plan Chose Limit',
  repeatOrderPlanSent: 'Repeat Order Plan Sent',
  repeatOrderExecuted: 'Repeat Order Executed',
  masterDataPersonalDetailsAdded: 'Master Data Personal Details Added',
  registerUsers: 'Register Users',
  loginUsers: 'Login Users',
  masterDataStartedUsers: 'Master Data Started Users',
  masterDataPersonalDetailsAdUsers: 'Master Data Personal Details Ad Users',
  addedMasterDataUsers: 'Added Master Data Users',
  createdBankAccountUsers: 'Created Bank Account Users',
  kycRedirectedToIdNowUsers: 'KYC Redirected To Id Now Users',
  kycPart1FinishedUsers: 'KYC Part1 Finished Users',
  kycRedirectedToChatUsers: 'KYC Redirected To Chat Users',
  kycFinishedUsers: 'KYC Finished Users',
  beginVaultCreationUsers: 'Begin Vault Creation Users',
  vaultTypeSelectedUsers: 'Vault Type Selected Users',
  vaultCreatedUsers: 'Vault Created Users',
  prepaidProcessStartedUsers: 'Prepaid Process Started Users',
  vaultDetailsCopiedUsers: 'Vault Details Copied Users',
  transferIbanCopiedUsers: 'Transfer IBAN Copied Users',
  masterDataPersonalDetailsAddedUsers: 'Master Data Personal Details Added Users',
  revenue: 'Revenue',
  cost: 'Cost',
} as const satisfies Omit<
Record<keyof GetCampaignStatsVariables, string>,
'startDate' | 'endDate'
>
export type ColumnLabels = Array<keyof typeof columnLabels>
const columnOptions = Object.keys(columnLabels) as ColumnLabels

const defaultColumns = {
  register: true,
  login: true,
} as const

export const CampaignStatistic = () => {
  const classes = useStyles()
  const notify = useNotify()

  const [shownColumns, setShownColumns] = useState<ColumnLabels>(
    Object.keys(defaultColumns) as ColumnLabels
  )
  const [isDialogOpen, setIsDialogOpen] = useState(false)
  const [startDate, setStartDate] = useState<string>()
  const [endDate, setEndDate] = useState<string>()

  const { loading, data, error, errors, refetch } = useQuery<
    GetCampaignStats,
    GetCampaignStatsVariables
  >(getCampaignStats, {
    variables: defaultColumns,
  })

  const handleOk = (selection: ColumnLabels) => {
    setIsDialogOpen(false)
    setShownColumns(selection)
    refetch({ ...toGqlVars(selection), startDate, endDate })
  }

  const handleStartDate = (date: string | null) => {
    setStartDate(date != null ? new Date(date).toJSON() : undefined)
  }
  const handleEndDate = (date: string | null) => {
    setEndDate(date != null ? new Date(date).toJSON() : undefined)
  }
  const submitDateFilter = () => {
    if (startDate > endDate) {
      notify(
        'Start date is later than end date. Thus there would be no results.'
      )
      return
    }

    refetch({
      ...toGqlVars(shownColumns),
      startDate: startDate != null ? setClientTimeZone(startDate) : null,
      endDate: endDate != null ? setClientTimeZone(endDate) : null,
    })
  }

  return (
    <Card className={classes.root}>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          gap: '1rem',
          marginBottom: '1em',
        }}
      > <div>
      <form noValidate style={{marginRight: 'auto'}}>
       <TextField
          id="dateStart"
          color="primary"
          label="From"
          type="date"
          onChange={(e) => {
            handleStartDate(e.target.value)
          }}
          InputLabelProps={{
            shrink: true,
          }}
          disabled={loading}
        />
        <TextField
          id="dateEnd"
          color="primary"
          label="To"
          type="date"
          onChange={(e) => {
            handleEndDate(e.target.value)
          }}
          InputLabelProps={{
            shrink: true,
          }}
          style={{ marginLeft: 30 }}
          disabled={loading}
        />
        <Button 
          onClick={submitDateFilter} 
          disabled={loading} 
          variant="contained"
          color="primary"
          style={{marginLeft: "1rem", marginTop: '.8rem'}}
        >
          {
            loading ? (
              <CircularProgress
                size="2rem"
                style={{ marginLeft: '1rem', marginTop: '.5rem' }}
              />
            ) : 'Submit'
          }
        </Button>
      </form></div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          gap: '1rem',
          marginBottom: '1em',
        }}>
        <DialogSelect
          isOpen={isDialogOpen}
          options={columnOptions}
          labels={columnLabels}
          currentSelection={shownColumns}
          handleClose={() => setIsDialogOpen(false)}
          handleOpen={() => setIsDialogOpen(true && !loading)}
          handleOk={handleOk}
        />
        <Button
          disabled={loading}
          variant="contained"
          color="primary"
          onClick={() =>
            csvExport(data.marketingStatistics.nodes, shownColumns)
          }
        >
          Export csv
        </Button></div>
      </div>
      <TableContainer className={classes.tableContainer}>
        <Table stickyHeader>
          <TableHead>
            <TableCell>Label</TableCell>
            {shownColumns.map((col) => (
              <TableCell key={col}>{columnLabels[col]}</TableCell>
            ))}
          </TableHead>
          <TableBody>
            {loading ? (
              <TableCell>Fetching campaign statistic</TableCell>
            ) : error || errors ? (
              <TableCell>
                An error occured when fetching the campaign statistic.
              </TableCell>
            ) : data ? (
              data.marketingStatistics?.nodes
                .filter(
                  (data) => (
                    shownColumns.every(col => data[col] != null) 
                    && ( 
                      data.channel?.trim() !== ''
                      && data.campaign?.trim() !== '' 
                      && data.adGroup?.trim() !== '' 
                      && data.ad?.trim() !== ''
                    )
                ))
                .map((data) => (
                  <TableRow
                    hover
                    key={`${data.channel}${data.campaign}${data.adGroup}${data.ad}`}
                  >
                    <TableCell>{getRowLabel(data)}</TableCell>
                    {shownColumns.map((col) => (
                      <TableCell key={col}>{data[col]}</TableCell>
                    ))}
                  </TableRow>
                ))
            ) : null}
          </TableBody>
        </Table>
      </TableContainer>
    </Card>
  )
}

function getRowLabel(
  labels: Record<'channel' | 'campaign' | 'adGroup' | 'ad', string | null>
): string {
  if (labels.ad != null) {
    return `___ ${upperFirst(labels.ad)}`
  }
  if (labels.adGroup != null) {
    return `__ ${upperFirst(labels.adGroup)}`
  }
  if (labels.campaign != null) {
    return `_ ${upperFirst(labels.campaign)}`
  }
  if (labels.channel != null) {
    return upperFirst(labels.channel)
  }
  return 'TOTAL'
}

/** Maps array of names, to an object with these names as keys, all set to true*/
function toGqlVars<T extends string[]>(selection: T): Record<T[number], true> {
  return selection.reduce(
    (acc, curr) => ((acc[curr] = true), acc),
    {} as Record<T[number], true>
  )
}

/** The date input, will not use the client's timezone, but rather use 00:00 UTC, 
 * so this replaces the UTC offset at the end of the JSON date, with the client's offset.
 * */
function setClientTimeZone(isoString: string): string {
  const rawOffset = new Date().getTimezoneOffset()
  const isNegative = rawOffset < 0
  const offsetMinutes = Math.abs(rawOffset) % 60
  const offsetHours = (Math.abs(rawOffset) - offsetMinutes) / 60
  const offset = `${isNegative ? '-' : '+'}${offsetHours}:${offsetMinutes}`

  return isoString.replace(/(?:Z|[\+-]\d{2}:\d{2})$/, offset)
}

function csvExport(
  data: GetCampaignStats['marketingStatistics']['nodes'],
  fields: Array<keyof typeof columnLabels>
) {
  const parser = new Parser({
    fields: [
      ...['channel', 'campaign', 'adGroup', 'ad'].map((label) => ({
        label,
        value: (row: typeof data[number]) => row[label] ?? '',
      })),
      ...fields.map((label) => ({
        label,
        value: (row: typeof data[number]) => row[label] ?? 0,
      })),
    ],
  })

  /** {@link https://simpletechs.atlassian.net/browse/FLEX-1966?focusedCommentId=95968} */
  const filtered = data.filter(
    (data) =>
      data.channel?.trim() !== '' &&
      data.campaign?.trim() !== '' &&
      data.adGroup?.trim() !== '' &&
      data.ad?.trim() !== ''
  )

  const blob = new Blob([parser.parse(filtered)], {
    type: 'text/csv;charset=utf-8;',
  })
  const url = URL.createObjectURL(blob)
  const link = document.createElement('a')
  link.setAttribute('href', url)
  link.setAttribute('download', 'marketing_statistic')
  link.click()
}

export default CampaignStatistic
