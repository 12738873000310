import { SplitButtonProps } from '../SplitActionButton'
import { identificationDatumSplitActions } from './identificationDatum'
import { NodeActionComponent } from './NodeActionType'
import PaymentScheduledCancellation from './paymentScheduledCancellation'
import Subscription from './subscription'
import SubscriptionTerm from './subscriptionTerm'
import TranscodingJob from './transcoding'
import User from './user'

export const NodeActions: {
  [typeName: string]: NodeActionComponent[]
} = {
  User,
  TranscodingJob,
  Subscription,
  PaymentScheduledCancellation,
  SubscriptionTerm,
}

Object.keys(NodeActions).forEach((type) => {
  const components = NodeActions[type]
  components.forEach((component) => {
    if (!component.label) {
      throw new Error('every nodeActions component requires a label')
    }
  })
})

export const SplitNodeActions: Record<string, SplitButtonProps['optionFn']> = {
  IdentificationDatum: identificationDatumSplitActions
}
