import {
  Box,
  FormControl,
  InputBase,
  MenuItem,
  Select,
  Typography,
  useMediaQuery,
  useTheme,
} from '@material-ui/core'
import { Pagination, PaginationItem, PaginationProps } from '@material-ui/lab'
import React, { useEffect, useState } from 'react'
import { useListContext } from 'react-admin'

// In our current version of react-admin (v3.18.3), pagination doesn't support
// highlighting the current page by default, so we've created a custom solution based on Material-UI (just like the react-admin version).
// FIXME: This component is no longer necessary when upgrading to react-admin v4,
// where pagination already supports the highlighting of the active page out of the box (see https://github.com/marmelab/react-admin/issues/5003)
interface CustomPaginationProps extends PaginationProps {
  rowsPerPageOptions?: number[] // we allow that property just like the original react-admin component would
}

export const CustomPagination: React.FC<CustomPaginationProps> = ({
  rowsPerPageOptions = [5, 10, 15, 25, 50, 75, 100], // Default options if not provided
  ...props
}) => {
  const { total, page, setPage, perPage, setPerPage } = useListContext()

  const theme = useTheme()
  // Check if the screen is small
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'))

  // Calculate the total number of pages
  const totalPages = Math.ceil((total || 0) / perPage)

  // Calculate the current range of items being displayed (e.g., 1-25 of 33822)
  const startRecord = (page - 1) * perPage + 1
  const endRecord = Math.min(page * perPage, total)

  const handlePageChange = (
    event: React.ChangeEvent<unknown>,
    value: number
  ) => {
    setPage(value)
  }

  // Handle rows per page change
  const handleRowsPerPageChange = (
    event: React.ChangeEvent<{ value: unknown }>
  ) => {
    const newPerPage = event.target.value as number

    // Recalculate the page to maintain the current visible range
    const currentStartIndex = (page - 1) * perPage
    const newPage = Math.floor(currentStartIndex / newPerPage) + 1

    setPerPage(newPerPage)
    setPage(newPage)
  }

  const [jumpToPage, setJumpToPage] = useState<string>('')

  const handleJumpToPageChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setJumpToPage(event.target.value)
  }

  const handleJumpToPageSubmit = () => {
    const pageNumber = parseInt(jumpToPage, 10)
    if (pageNumber > 0 && pageNumber <= totalPages) {
      setPage(pageNumber)
    }
  }

  // We clear the "jump-to-page"-input field on page change, feels cleaner..
  useEffect(() => {
    setJumpToPage('')
  }, [page])

  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent={isSmallScreen ? 'space-between' : 'flex-end'}
      mt={2}
      mb={2}
      width="100%"
      flexDirection={isSmallScreen ? 'column' : 'row'} // Stack vertically on small screens
    >
      {/* Rows per page text and dropdown */}
      <Box
        display="flex"
        alignItems="center"
        mr={isSmallScreen ? 0 : 2}
        mb={isSmallScreen ? 2 : 0}
      >
        <Typography
          variant="body2"
          color="textSecondary"
          style={{ marginRight: 8 }}
        >
          Rows per page:
        </Typography>
        <FormControl variant="standard" size="medium">
          <Select
            disableUnderline={true}
            value={perPage}
            onChange={handleRowsPerPageChange}
            style={{ minWidth: 50 }}
          >
            {rowsPerPageOptions.map((option) => (
              <MenuItem key={option} value={option}>
                {option}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>

      {/* Range info about the items being displayed */}
      <Box mr={isSmallScreen ? 0 : 2} mb={isSmallScreen ? 2 : 0}>
        <Typography variant="body2" color="textSecondary">
          {startRecord}-{endRecord} of {total}
        </Typography>
      </Box>

      {/* Jump to Page Input */}
      <Box display="flex" alignItems="center" mr={isSmallScreen ? 0 : 2}>
        <Typography
          variant="body2"
          color="textSecondary"
          style={{ marginRight: 8 }}
        >
          Jump to page:
        </Typography>
        <Box display="flex" alignItems="center">
          <InputBase
            value={jumpToPage}
            onChange={handleJumpToPageChange}
            onKeyPress={(e) => e.key === 'Enter' && handleJumpToPageSubmit()}
            onBlur={handleJumpToPageSubmit} // Trigger the jump when the input field loses focus
            type="number"
            inputProps={{
              min: 1,
              max: totalPages,
            }}
            style={{
              width: 60,
              marginRight: 4,
              borderBottom: '1px solid #ccc',
              padding: '2px 5px',
            }}
          />
        </Box>
      </Box>

      {/* Pagination control */}
      <Box>
        <Pagination
          count={totalPages}
          page={page}
          onChange={handlePageChange}
          color="primary"
          renderItem={(item) => (
            <PaginationItem {...item} selected={item.page === page} />
          )}
          {...props}
        />
      </Box>
    </Box>
  )
}
